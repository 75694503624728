#darkmode-toggle {
  list-style-type: none;
  color: #000;
  font-size: 1.2em;
  cursor: pointer;

  .icon-lightmode,
  .icon-hover,
  .icon-darkmode {
    display: none;
    padding: 12px;
  }

  &.darkmode .icon-darkmode,
  &.lightmode .icon-lightmode {
    display: block;
  }
  &.darkmode .icon-darkmode{
    color: #FFF;
  }


  &:hover {
    .icon-lightmode,
    .icon-darkmode {
      display: none;
    }

    .icon-hover {
      display: block;
      color: #000;
      background: transparent;
    }
    &.darkmode .icon-hover {
      color: #FFF;
    }
  }
}
