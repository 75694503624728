.order-edit-add-product .line-checkbox,
.order-edit-add-product .line-radio {
    margin: 5px 0;
}

.order-edit-add-product .line-checkbox input,
.order-edit-add-product .line-radio input {
    display: none;
    float: left;
    width: 18px;
}

.order-edit-add-product .line-checkbox label,
.order-edit-add-product .line-radio label {
    display: inline-block;
    position: relative;
    margin: 0;
    clear: none;
    padding: 0 0 0 28px;
    cursor: pointer;
}

.order-edit-add-product .line-checkbox label {
    padding-top: 5px;
}

.order-edit-add-product .line-checkbox input[type='checkbox'] + label:before {
    font-family: FontAwesome, Arial, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    content: "\F096";
    width: 24px;
    height: 24px;
    border-radius: 0;
    border: none;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(51, 51, 51, .5);
}

.order-edit-add-product .line-checkbox input[type='checkbox']:checked + label:after {
    font-family: FontAwesome, Arial, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    content: "\F046";
    width: 24px;
    height: 24px;
    border-radius: 0;
    border: none;
    background-color: white;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(51, 51, 51, .5);
}

.order-edit-add-product .line-radio input[type='radio'] + label:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #bbb;
    background-color: white;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.order-edit-add-product .line-radio input[type='radio']:checked + label:after {
    content: '\F111';
    font-family: FontAwesome, Arial, sans-serif;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    z-index: 2;
    font-size: 9px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(51, 51, 51, .5);
}

.order-edit-add-product .available-variations,
.order-edit-add-product .available-options {
    display: none;

    margin-top: 20px;
}

.order-edit-add-product .available-variations p,
.order-edit-add-product .available-options p {
    margin-bottom: 0;
}

.order-edit-add-product .available-options .line-checkbox {
    display: none;
}

.order-edit-add-product .btn-save-personalisation,
.order-edit-add-product .btn-edit-personalisation {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 10px;
}

.panel-letter {
    display: none;
}

.panel-card {
    display: none;
}

.panel-card .card-container {
    display: flex;
}

.panel-card .card-container .card-list {
    width: 175px;
}

.panel-card .card-container .card-text {
    width: 354px;

    position: relative;
    height: 251px;
    padding-right: 60px;
    border: 1px solid #ccc;
    background-image: url('../../images/card_bg.png');
    background-size: 100% 100%;
}

.panel-card .card-container .card-text textarea {
    background-color: transparent;
    border: none;
    padding: 10px 13px;
    margin: -1px 0 0 0px;
    width: 100%;
    height: calc(100% + 2px);
    font-family: 'Arial Narrow', sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    overflow: hidden;
    color: #000;
    letter-spacing: 1px;
    display: table-cell;
    vertical-align: middle;
    white-space: pre-wrap;
    word-wrap: break-word;
    resize: none;
    position: relative;
    z-index: 2;
}

.panel-card .card-container .card-image {
    position: absolute;
    top: -1px;
    right: 0;
    width: 218px;
    height: calc(100% + 2px);
    background: no-repeat center right;
    border: 1px solid #ccc;
    border-left: 0;
    z-index: 1;
    display: none;
}

.panel-personalization {
    display: none;
}

.panel-personalization .personalization-container {
    width: 100%;
}

.panel-personalization .personalization-container iframe {
    width: 100%;
    height: 650px;

    border: none;
    overflow: hidden;
}
