.rule-builder {
    visibility: visible;
}

.rule-builder.loading {
    position: relative;

    visibility: hidden;
}

.rule-builder.loading:after {
    content: 'Filters worden geladen, een moment geduld.';
    text-align: center;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    visibility: visible;
}

.rule-builder .btn-xs {
    padding: 3px 10px;
}

.rule-builder .rule-container,
.rule-builder .rule-placeholder,
.rule-builder .rules-group-container {
    border-color: #ddd;
    border-radius: 0;
}

.rule-builder .rules-group-container {
    background: rgba(210, 210, 210, 0.16);
    border: 1px solid #ccc;
}

.rule-builder .rules-group-header .group-actions {
    margin-left: auto;
}

.rule-builder .add-item-stack {
    position: absolute;

    font-size: 9px;

    top: auto;
    bottom: 0;
    left: 18px;

    background: #4CAF50;
    color: #fff;
    border-radius: 50%;
    padding: 1px;
}

.rule-builder .rules-group-header {
    display: flex;
}

.rule-builder .rules-group-header .group-conditions {
    display: flex;
    align-items: center;
}

.rule-builder .rules-group-header .group-conditions .btn {
    border-color: #ccc;
    margin-left: 0;
    border-left-width: 0;
}

.rule-builder .rules-group-header .group-conditions .btn:first-child {
    border-left-width: 2px;
}

.rule-builder .group-conditions .group-description {
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 11px;
}

.rule-builder .rule-container {
    display: flex;
    align-items: center;
}

.rule-builder .rule-container .rule-actions {
    float: none !important;

    margin-left: auto;
}

.rule-builder .rule-container .rule-header {
    margin-left: auto;
}

.rule-builder .rule-container .rule-filter-container {
    width: 35%;
}

.rule-builder .rule-container .rule-operator-container {
    width: 12%;

    margin: 0 10px;
}

.rule-builder .rule-container .rule-operator-container > span {
    display: inline-block;

    width: calc(100% - 16px);
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
}

.rule-builder .rule-container .rule-operator-container .select2-container {
    text-align: center;

    font-size: 11px;
    text-transform: uppercase;
}

.rule-builder .rule-container .rule-value-container {
    width: 35%;

    padding-left: 0;
    border-left: 0;
    margin-left: 5px;

    position: relative;
}

.rule-builder .rule-container .rule-value-container input[type=text] {
    width: 100%;

    height: 32px;
}

.rule-builder .rule-container .select2 {
    width: 100% !important;
}

.rule-builder .rule-container .rule-value-container.rule-value-container-between input[type=text] {
    width: calc(50% - 20px);
    text-align: center;
}

.rule-builder .rule-container .rule-value-container input.daterangepicker {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.rule-builder .rule-container .rule-value-container .input-separator {
    display: inline-block;

    width: 40px;
    text-align: center;
}

.rule-builder .rule-container .rule-value-container .bootstrap-switch {
    font-size: 10px;
    text-transform: uppercase;
}

.rule-builder .rule-container .rule-filter-container .filter-property-placeholder {
    font-size: 11px;
    text-transform: uppercase;
}

.rule-builder .rule-container .rule-filter-container .filter-property-name {
    font-size: 11px;
    text-transform: uppercase;
}

.rule-builder .rule-container .rule-filter-container .filter-property-path {
    font-size: 10px;
    color: #ccc;
    text-transform: uppercase;

    float: right;

    right: 10px;

    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rule-builder.disabled {
    pointer-events: none;
    opacity: 0.6;
}
