#order_toolbar {
}

#order_details .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
}

#order_details .btn-panel-heading {
    margin-left: auto;
    padding: 0.5rem 1.25rem;
    color: #fff;
}

#order_details .panel-body a {
    color: #000;
}

#order_details .customer-information-line {
    margin-bottom: 10px;
    padding-bottom: 10px;

    border-bottom: 1px dotted #ccc;
}

#order_details .address-line {
    margin-bottom: 10px;
    padding-bottom: 10px;

    border-bottom: 1px dotted #ccc;
}

#order_details .contact-line {
    margin: 5px 0;
}

#order_details i.fa {
    display: inline-block;

    width: 16px;
}

.heading-elements .icons-list {
    margin-right: 12px;
    margin-top: 12px;
}

#order-orders {
    padding-bottom: 30px;
}

.order-collection .panel .icons-list a[data-action=collapse] {
    display: none;
}

.order-collection .panel-collapsed .icons-list a[data-action=collapse] {
    display: block;
}

.incl-vat {
    display: none;
}

.fa-star,
.fa-star-half-o,
.fa-star-o {
    font-size: 16px;
    line-height: 36px;
    margin-left: 1px;
}

.order-order .panel-heading {
    cursor: pointer;
}

.order-order .panel-heading .row {
    display: flex;
}

.order-order .panel-heading .row > div {
    align-self: center;
}

.order-order .address-line,
#order_edit .address-line {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
}

.order-order .fa,
#order_edit .fa {
    width: 16px;
}

#order_edit {

}

#order_edit .btn-order-edit-add-product {
    display: none;
}

#order_edit .form-control-static {
    padding-left: 8px;
    padding-right: 8px;
}

#order_edit [readonly],
#order_edit [disabled] {
    border: transparent !important;
    box-shadow: none !important;
    color: #333333 !important;
    line-height: 1.4;
}

#order_edit .order-line [readonly],
#order_edit .order-line [disabled] {
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-left: 0;
    padding-right: 0;
}

#order_edit .select2-container--disabled .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
    border: transparent !important;
    box-shadow: none !important;
    color: #333333 !important;
}

#order_edit .select2-container--disabled .select2-selection--single:not([class*=bg-]):not([class*=border-]) .select2-selection__arrow {
    visibility: hidden !important;
}

#order_edit .line-delete {
    width: 40px !important;
}

#order_edit.disabled .line-delete {
    display: none;
}

#order_edit .line-delete a {
    color: red;
}

#order_edit .column-quantity {
    width: 70px;
}

#order_edit .column-quantity input {
    width: 100%;
    text-align: center;
}

#order_edit .align-middle {
    vertical-align: middle;
}

#order_edit #order_edit_add_line {
    display: none;

    padding: 3px 7px !important;
}

#order_edit .panel-order-lines .panel-body {
    position: relative;
}

#order_edit .element-product {
    width: 400px !important;
}

#order_edit td.column-metadata {
    display: none;
}

#order_edit tr:not(.child-order-line) td.column-product select {
    width: 400px !important;
}

#order_edit tr.child-order-line td.column-product {
    position: relative;
}

#order_edit tr.child-order-line td.column-product select {
    position: relative;

    margin-left: 25px;
    width: 375px !important;
}

#order_edit tr.child-order-line td.column-product::before {
    content: "\f064";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: scale(1, -1);
    width: 16px;
    font-size: 12px;
}

#order_overview:not(.static-display) tr.child-order-line td.column-product::before {
    position: absolute;
    top: 11px;
    left: 7px;
}

#order_edit .panel-order-lines .order-edit-information {
    left: 10px;
    bottom: 10px;
}

#order_edit .panel-order-lines .order-edit-information p {
    font-weight: 300;
    color: #595959;
    font-size: 12px;
    line-height: 20px;
}

#order_edit .panel-order-lines .order-edit-information-open {
    font-weight: 300;
    color: #595959;
    font-size: 12px;
    line-height: 20px;

    display: none;
}

#order_edit.disabled .panel-order-lines .order-edit-information-blocked {
    display: none;
}

#order_edit:not(.disabled) .panel-order-lines .order-edit-information-open {
    display: block;
}

#order_edit .column-price,
#order_edit .column-price-incl,
#order_edit .column-price-total {
    width: 120px;
    text-align: right;
}

#order_edit .column-discount {
    width: 200px;
    text-align: right;
}

#order_edit .column-vat {
    width: 90px;
    text-align: center;
}

#order_overview:not(.static-display) .column-vat {
    display: none;
}

#order_overview:not(.static-display) td.column-vat-percentage {
    padding: 10px;
}

#order_overview:not(.static-display) td.column-price-total {
    padding: 10px;
}

#order_edit .panel-delivery .picker__input[readonly] {
    color: #333333;
    background-color: #fff;
}

#order_edit .panel-delivery .date_picker .form-control-feedback {
    height: 32px;
    line-height: 32px;
}

#order_edit .panel-delivery dt {
    font-weight: bold;
}

#order_edit .delivery-information .delivery-information-toggle > span {
    text-decoration: underline;
    cursor: help;
}

#order_edit .delivery-information .delivery-information-toggle-label:after {
    content: attr(data-show-label);
}

#order_edit .delivery-information.open .delivery-information-toggle-label:after {
    content: attr(data-hide-label);
}

#order_edit .delivery-information .well.delivery-information-description {
    display: none;
}

#order_edit .delivery-information.open .well.delivery-information-description {
    display: block;
    margin-top: 10px;
}

#order_edit .delivery-information .fa-angle-up {
    display: none;
}

#order_edit .delivery-information .fa-angle-down {
    display: inline-block;
}

#order_edit .delivery-information.open .fa-angle-up {
    display: inline-block;
}

#order_edit .delivery-information.open .fa-angle-down {
    display: none;
}

#order_edit .delivery-information.open .well.delivery-information-description {
    display: block;
}

#order_edit #order_overview tr.invalid,
#order_edit #order_overview tr.invalid input
{
    color: red !important;
}

#order_edit #order_overview.disabled .order-line-delete,
#order_edit #order_overview.disabled .order-line-edit {
    display: none;
}

#order_edit #order_overview tr.total-line td {
    padding-top: 10px;
    text-align: right;
}

#order_edit #order_overview tr.total-line-price-excl,
#order_edit #order_overview tr.total-line-vat {
    display: none;
}

#order_edit #order_overview tr.total-line-price-incl td {
    font-weight: bold;
}

#order_edit [data-transport-type="1"]{
    position: relative;
}
#order_edit [data-transport-type="1"] td{
    filter: blur(1px);
}
#order_edit [data-transport-type="1"]:after{
    position: absolute;
    content: "Let op: Bezorgproduct wordt opnieuw bepaald bij opslaan";
    left: 0;
    background: rgba(255,225,225 0.6);
    display: block;
    width: 100%;
    line-height: 40px;
    text-align: center;
    text-shadow: 0px 0px 5px #FFF;
 }

.swa-send-invoice .swal-send-invoice-input {
    text-align: center;
}

.active td {
    background-color: #d6e9c6 !important;
    color: #3c763d;
}

a.order-line-edit,
a.order-line-edit-link {
    color: #333333;
}

#card-preview {
    position: relative;

    width: 354px;
    height: 251px;
    padding-right: 60px;
    border: 1px solid #ccc;
    background-image: url('../../images/card_bg.png');
    background-size: 100% 100%
}

#card-preview.small {
    width: 100%;
    height: 0;
    padding-right: 0;
    padding-top: 47.54%;
    position: relative;
    background-image: url('./../../images/card_bg_small.jpg');
    background-size: 200% 100%;
    background-position: right;
}

#card-preview .textarea {
    border: none;
    padding: 10px 13px;
    margin: -1px 0 0 0;
    width: 100%;
    height: calc(100% + 2px);
    font-family: 'Arial Narrow', sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    overflow: hidden;
    color: #000;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
}

#card-preview.small .textarea {
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#card-preview .image {
    position: absolute;
    top: -1px;
    right: 0;
    width: 218px;
    height: calc(100% + 2px);
    background-position: center left;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-left: 0;
    z-index: 1;
}

#card-preview.small .image {
    display: none;
}

.btn-unlock {
    position: relative;
    top: -7px;
}

.design-list {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
}

.design-list__img {
    align-self: center;
    background-color: #fff;
    outline: thin solid hsla(60 1% 21%/30%);
    box-shadow: 0 1.5rem 2rem #0000000a;
}

.design-list__error {
    align-self: center;
}
