@import "variables";
@import "components/components";

.text-wrap-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.btn [class^="icon-"] {
  font-size: 15px;
  margin: -1px 5px 0 0;
}

.dropdown-menu > li > a > i,
.dropdown-menu > .dropdown-header > i {
  margin-top: 0;
}

.link--disabled {
  opacity: .5;
  cursor: default;
}

.site-watermark {
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  min-width: 150px;
  padding: 5px 10px;
  background-color: #1976d2; /* default */
  color: #fff;
  font-size: 16px;
}

.row-initial {
  display: initial;
}
