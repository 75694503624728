#supplier_order_form_panel .loader {
    height: 80px;
    position: relative;
}

#supplier_order_form_panel .loader::before {
    position: absolute;
    left: 50%;
    top: 50%;

    color: #bbb;

    transform: translate(-50%, -50%);

    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    min-width: 1em;
    display: inline-block;
    text-align: center;
    font-size: 36px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\eb51";
    animation: rotation 1s linear infinite;
}
#supplier_order_form_panel th,
#supplier_order_form_panel input[readonly="readonly"] {
    border: 0;
    padding: 0 2rem 0 0;
}

#supplier_order_form_panel th:nth-child(3),
#supplier_order_form_panel td:nth-child(3) input {
    text-align: center;
}

.panel-supplier-selection tr td {
    vertical-align: top !important;
    height: 110px;
}

