.copy-to-clipboard {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #111;
  visibility: hidden;
}

.copy-to-clipboard:hover {
  cursor: pointer;
  visibility: visible;

  &:after {
    background: #111;
    bottom: 1.75em;
    color: #fff;
    content: 'Kopieer naar het klembord';
    display: block;
    padding: .3em 1em;
    position: absolute;
    text-shadow: 0 1px 0 #000;
    white-space: nowrap;
    z-index: 98;
  }

  &:before {
    border: solid;
    border-color: #111 transparent;
    border-width: .4em .4em 0 .4em;
    bottom: 1.35em;
    content: "";
    display: block;
    left: 2em;
    position: absolute;
    z-index: 99;
  }
}

i.copy-reveal {
  padding-right: 4px;
}

i.copy-reveal:hover {
  cursor: pointer;
}

i.copy-reveal:hover + .copy-to-clipboard {
  visibility: visible;
}
