.collection-list i {
    color: #757575;
}

.collection-list .actions:after {
    clear: both;
    content: '';
    display: block;
}

.collection-list .actions .btn-right {
    float: right;
}

.collection-list .actions .btn-add i {
    margin-right: .25rem;
}

.collection-list .btn-add {
    color: #757575;
    padding: .5rem 1rem;
    font-size: 11px
}

.collection-list .btn-add,
.collection-list .btn-add:hover {
    background-color: transparent;
    box-shadow: none;
}

.collection-list .btn-add:hover {
    opacity: .8;
}

.collection-list .btn-add i {
    font-size: 12px;
}

.collection-list i.icon-plus2 {
    border: 2px #757575 solid;
    border-radius: 100px;
    padding: 1px;
}

.collection-list-items ul {
    list-style: none;
}
.collection-list-items ul,
.collection-list-items li {
    margin: 0;
    padding: 0;
}

.collection-list-items .list-item {
    border: 1px #ddd solid;
    margin-bottom: .5rem;
    background-color: #fff;
    overflow: hidden;
}

.collection-list-items .list-item.ui-sortable-helper,
.collection-list-items .list-item.collection-list-item-placeholder{
    height: 44px !important;
}

.collection-list-items .list-item.collection-list-item-placeholder {
    border: 1px #ddd dashed;
    background-color: #fcfcfc;
}

.collection-list-items .field-container:not(:last-child) {
    margin-bottom: 3px;
}

.collection-list-items .field-container:after {
    content: '';
    display: block;
    clear: both;
}

.collection-list-items .field-container > label  {
    float: left;
    width: 40%;
    padding: 7px 0 0 0;
    margin: 0;
}

.collection-list-items .field-container .field {
    width: 100%;
}

.collection-list-items .field-container > label + .field,
.collection-list-items .field-container.form-group .field.field-checkbox {
    float: right;
    width: 60%;
}

.collection-list-items .list-heading {
    position: relative;
    height: 42px;
    overflow: hidden;
}

.collection-list-items .list-heading .drag-handle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    font-size: 24px;
    opacity: .5;
    z-index: 10;
    cursor: move;
}

.collection-list-items .list-heading .labels {
    float: left;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 0 0 0 3rem;
}

.collection-list-items .list-heading .actions {
    float: right;
    position: relative;
    top: 50%;
    margin-right: 1rem;
    transform: translateY(-50%);
}

.collection-list-items .list-heading .actions ul {
    list-style: none;
}

.collection-list-items .list-heading .actions ul,
.collection-list-items .list-heading .actions li {
    float: left;
}

.collection-list-items .list-heading .action {
    color: rgba(0, 0, 0, .54);
    margin-left: .25rem;
}

.collection-list-items .list-heading .actions .btn {
    width: 24px;
    height: 24px;
    font-size: 16px;
    padding: 0;
    background: #fff;
}

.collection-list-items .list-heading .actions .btn:hover {
    opacity: .75;
    box-shadow: none;
}

.collection-list-items .list-body {
    position: relative;
    padding: 1rem;
    display: none;
}

.collection-list-items .collection-list-item.show > .list-body {
    display: block;
}

.collection-list-items .list-body:before {
    position: absolute;
    content: '';
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 1px;
    background: #ddd;
    box-sizing: border-box;
}

.field-collection-container .field > label {
    margin-top: 1rem;
    font-weight: bold;
    border-bottom: 1px #ddd solid;
    display: block;
    padding: .5rem 0;
}