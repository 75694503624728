.view.view--full-vh {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 63px);
    margin: -10px;
}

.collection-view {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: calc(100vh - 63px);
    padding: .25rem;
}

.collection-view__left {
    box-sizing: border-box;
    flex: 0 0 32%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 63px);
    padding: .25rem .25rem 0;
}

.collection-view__right {
    box-sizing: border-box;
    flex: 0 0 65%;
    overflow-y: auto;
    height: calc(100vh - 63px);
    padding: .25rem .25rem 0;
}

.view .panel-heading {
    padding: 10px;
}

.view .panel-heading .heading-elements {
    margin-top: -19px;
    right: 0;
}

.view .panel-heading .row .heading-elements {
    margin-top: -9px;
    right: 0;
}

.view .panel-heading .heading-elements .btn-xs {
    margin-top: 4px;
    margin-right: 4px;
    margin-left: 3px;
    padding: 4px 10px;
}

.view .panel-heading .heading-elements .btn-flat {
    padding: 3px 10px;
}

.view .panel-heading .panel-title {
    font-weight: bold;
}

.view .panel-heading .panel-title .label {
    margin-left: 5px;
}

.view .panel-tab-content,
.view .panel-body {
    padding: 10px;
}

.view .panel-body h1,
.view .panel-body h2,
.view .panel-body h3,
.view .panel-body h4,
.view .panel-body h5 {
    margin-top: 0px;
}

.view .panel-body .table-borderless td {
    border: none !important;
}

.view .panel-body table.table > tbody > tr > td {
    border-color: #f5f5f5;
}

.view .panel-body table.table > tbody > tr:nth-child(2) > td {
    border-top: none;
}

.view .panel-body table.table > tbody > tr > th {
    border: none !important;
}

.view .panel-body table.table th {
    text-align: left;
    font-weight: bold;
}

.view .panel-body table.table th.text-right {
    text-align: right;
}

.view .panel-body table.table th,
.view .panel-body table.table td {
    vertical-align: top;
    padding: 3px 5px;
}

.view .panel-body table.table tr th:first-child,
.view .panel-body table.table tr td:first-child {
    white-space: nowrap;
    padding-left: 0;
}

.view .panel-body table.table-data tr th:first-child,
.view .panel-body table.table-data tr td:first-child {
    width: 150px;
}

.view .panel-body table.table tr.cell-border-top td,
.view .panel-body table.table td.cell-border-top {
    border-top: 1px solid #dddddd;
}

.view .panel-body table.table-pull-up {
    margin-top: -50px;
}

.view .panel-body table.table-pull-up th {
    height: 50px;
    padding-top: 4px;
    padding-bottom: 14px;
    vertical-align: middle;
    line-height: 16px;
}

.view-action .panel {

}

.view-action .panel-subtitle {
    padding: 10px;
    margin-top: 0;

    border-bottom: 1px solid #ddd;
}

.view-action .panel .tabbable {
    margin: -20px;
}

.view-action .panel .tabbable .nav-tabs {
    margin-bottom: 0px;
}

.view-action .panel .panel-tab-content {
    padding: 20px 20px 0 20px;

    background: #f9f9f9;
}

.view-action .panel .panel-tab-content.vue-tab {
    padding: 12px 24px 0 24px;
}

.view-action .panel .panel-tab-content.datatable {
    padding: 0px;
    background: #fff;
}

.panel.filter-expanded {
    margin-right: 280px;
}

.view-action .panel.filter-expanded {
    margin-right: 0
}

.view-action .panel.filter-expanded > .panel-body {
    margin-right: 280px;
}

.panel.filter-expanded .panel {
    margin-bottom: 1rem;
}

.view-action .panel.filter-expanded .panel {
    border: 1px #ddd solid;
    box-shadow: none;
}

.panel.filter-expanded .datatable-filters .actions {
    margin-bottom: 2rem;
}

.panel.filter-expanded .datatable-filters .actions:after {
    content: '';
    clear: both;
    display: block;
}

.panel.filter-expanded .collapse-filter {
    float: left;
    cursor: pointer;
    width: 26px;
    height: 26px;
    position: relative;
    border: 1px #259589 solid;
    border-radius: 26px;
    box-sizing: border-box;
    background: #259589;
}

.panel.filter-expanded .collapse-filter:hover {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
     box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.panel.filter-expanded .collapse-filter i {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.panel.filter-expanded .clear-filters a {
    color: #212121;
    padding: 3px 8px;
    font-size: 11px;
    float: right;
    border: 1px #e0e0e0 solid;
}

.panel.filter-expanded .datatable {
    position: relative;
}

.panel .datatable .datatable-filters {
    display: none;
}

.panel.filter-expanded .datatable .datatable-filters {
    display: block;
    position: absolute;
    right: -280px;
    width: 270px;
}

.view-action .datatable .datatable-header .actions {
    display: none;
}

.view-action .panel .panel-tab-content .panel-heading {
    padding-top: 10px;
    padding-bottom: 10px;
}

.view-action .panel .panel-tab-content .panel-body {
    overflow-x: auto;
}

.view-action .panel .panel-tab-content.loading,
.view-action .panel.loading {
    position: relative;

    color: #bbb;
}

.view-action .panel .panel-tab-content.loading,
.view-action .panel.loading .panel-body {
    height: 80px;

    position: relative;
}

.view-action .panel .panel-tab-content.loading:after,
.view-action .panel.loading:after {
    position: absolute;
    left: 50%;
    top: 50%;

    margin-left: -18px;
    margin-top: -18px;

    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    min-width: 1em;
    display: inline-block;
    text-align: center;
    font-size: 36px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\eb51";
    animation: rotation 1s linear infinite;
}

.view-action .panel .panel-tab-content .table-wrapper {
    margin: -20px -20px 0 -20px;
}

.view-action .panel .panel-tab-content .table-xxs {

}

.view-action .panel .panel-tab-content .table-xxs > thead > tr > th,
.view-action .panel .panel-tab-content .table-xxs > tbody > tr > th,
.view-action .panel .panel-tab-content .table-xxs > tfoot > tr > th,
.view-action .panel .panel-tab-content .table-xxs > thead > tr > td,
.view-action .panel .panel-tab-content .table-xxs > tbody > tr > td,
.view-action .panel .panel-tab-content .table-xxs > tfoot > tr > td {
    padding-left: 20px;
    padding-right: 20px;
}

.view-action .panel .panel-tab-content .table-xxs thead {
    background: #f9f9f9;
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
    font-weight: normal;
}

.view-action .panel .panel-tab-content .table-xxs thead th {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.view-action .panel .panel-tab-content .table-xxs tbody tr:last-child td {
    border-bottom: 1px solid #ddd;
}

.view-action .panel .panel-tab-content .table-xxs a {
    color: rgba(0, 0, 0, 0.54);
}

.view-action .panel .panel-tab-content .button-wrapper {
    margin-top: 20px;
}
