/**
* Multiselect
*/
.has-success .multiselect-container .checkbox {
    color: inherit !important;
}

.radio .choice span.has-error {
    border-color: #d84315;
}

/**
* Select2
*/
.select2-selection--single {
    height: 32px;
}

/**
* Date picker
*/
.date_picker input.form-control {
    width: 225px;
}

/**
* Time picker
*/
.time_picker input.form-control {
    width: 85px;
}

/**
* Datetime picker
*/
.datetime_picker {

}

.datetime_picker .datetime_picker_input_container {
    display: inline-block;
}

.datetime_picker .datetime_picker_input_container .form-control-feedback-time {
    left: auto;
    right: 48px !important;
}

.datetime_picker .datetime_picker_input_container .picker--time .picker__holder {
    right: 0px;
}

.datetime_picker input.date {
    display: inline-block;

    width: 225px;

    margin-right: 5px;
}

.datetime_picker input.time {
    display: inline-block;

    width: 85px;
}

.collection-items .btn-add {
    position: absolute;
    z-index: 1000;
    top: -43px;
    right: 0;
    padding: .35rem .75rem;
}

.collection-items.designer_collage_form_container_objects_objects_form_group .btn-add {
    top: -69px;
    right: 0;
}


.tabbable .collection-items .btn-add {
    top: -60px;
    right: -10px;
}

.tabbable .panel .collection-items .btn-add {
    top: -6px;
    right: 0;
}

.collection-items .table-condensed > tbody > tr > td,
.collection-items .table-condensed > tbody > tr > th,
.collection-items .table-condensed > tfoot > tr > td,
.collection-items .table-condensed > tfoot > tr > th {
    padding: 2px;
}

.tabbable .collection-items .table > tbody > tr:first-child td {
    /*padding-top: 4px !important;*/
}

.collection-items .table.collection-without-heading > tbody > tr:first-child td {
    padding-top: 0 !important;
}

.collection-items .table > thead > tr > td:first-child,
.collection-items .table > thead > tr > th:first-child,
.collection-items .table > tbody > tr td:first-child {
    padding-left: 0 !important;
}

.collection-items .table > thead > tr > td:last-child,
.collection-items .table > thead > tr > th:last-child,
.collection-items .table > tbody > tr td:last-child {
    padding-right: 0 !important;
}

.collection-items .table > tbody > tr > td {
    border-top: none !important;

    vertical-align: top;
}

.collection-items .has-feedback-left .form-control {
    padding-left: 36px;
}

.collection-items .has-feedback-right .form-control {
    padding-right: 36px;
}

.collection-items .has-feedback-left .form-control-feedback {
    top: 2px;
    left: 2px;
}

.collection-items .has-feedback-right .form-control-feedback {
    top: 2px;
    right: 2px;
}

.collection-items .table > tbody > tr > td .form-control {
    /* width: auto !important; */
}

.collection-items .table-condensed > thead > tr > td,
.collection-items .table-condensed > thead > tr > th {
    padding: 2px 3px;

    border-bottom: none;

    vertical-align: bottom !important;
}

.collection-items .drag-handle {
    width: 16px;

    padding: 4px 0 2px 0px !important;
}

.collection-items .drag-handle > div {
    cursor: move;
    /*border: 1px solid #ddd;
    background: #f2f2f2;*/

    width: 16px;
    height: 16px;
}

.collection-items .sortable-column {
    display: none;
}

.collection-items tr.sorting-row td {
    background-color: #8b8;
}

.collection-items tr.sorting-row td.drag-handle {
    background-color: inherit !important;
}

.collection-items .actions {
    width: 0;
}

.collection-items .collection-item--disabled {
    background: #f1f1f1;
}

.multiselect-container > li > a .checker,
.multiselect-container > li > a .choice {
    top: 50% !important;
}

.multi-select-full button.multiselect.border-info {
    border-color: #00BCD4 !important;
}

.media-widget {

}

.media-widget .media-widget-uploader {
    display: none;
}

.media-widget .media-widget-filename {
    padding: 7px 8px;
    border-bottom: 1px #ddd solid;
}

.media-widget .media-widget-thumbnail-container {
    width: 100%;
    max-width: 150px;
    position: relative;
    color: #464646;
    cursor: pointer;
    list-style: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.media-widget .media-widget-thumbnail-container > div:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.media-widget .media-widget-thumbnail-container > div {
    position: relative;
    -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05);
    background: #eee;
    cursor: pointer;
}

.media-widget .media-widget-thumbnail-image {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: opacity .1s;
    transition: opacity .1s;

    background-image: url('./../../../images/bg_transparancy_pattern.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.media-widget .media-widget-thumbnail-image .centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.media-widget .media-widget-thumbnail-image:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
    overflow: hidden;
}

.media-widget .media-widget-thumbnail-image img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.media-widget .media-widget-thumbnail-image .spinner {
    position: absolute;
    top: -8px;
    left: -8px;
    max-height: 100%;
}

.media-widget .media-widget-thumbnail-image img + i.spinner {
    display: none;
}

.media-widget > a {
    display: block;

    margin-top: 5px;
    font-size: 11px;
}

.plupload_file_name .file-filter-error {
    display: inline-block;

    margin-right: 10px;
    color: red;
}

/*@media only screen and (min-width: 769px) {*/
/*.plupload_filelist .plupload_file_name {*/
/*position: relative;*/
/*}*/

/*.plupload_file_name .file-filter-error {*/
/*position: absolute;*/

/*right: 0;*/
/*}*/
/*}*/

.recurring-event-widget {
    position: relative;
    height: 93px;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(221, 221, 221);
    padding-left: 3px;
}

.recurring-event-widget .recurring-event-selector {
    width: 150px;
    float: left;
}

.recurring-event-widget > div {
    height: 100%;
}

.recurring-event-widget .recurring-event-group {
    padding-left: 10px;
    height: 100%;
    border-left: 1px solid rgb(221, 221, 221);
    background-color: rgb(244, 244, 244);
    background: -webkit-linear-gradient(left, rgb(244, 244, 244) 0%, rgb(250, 250, 250) 100%);
}

.recurring-event-widget .recurring-event-group {
    position: relative;
    width: 300px;
    float: left;
    white-space: nowrap;
    display: none;
}

.recurring-event-widget .recurring-event-group:after,
.recurring-event-widget .recurring-event-group:before {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.recurring-event-widget .recurring-event-group:nth-child(1):before,
.recurring-event-widget .recurring-event-group:nth-child(1):after {
    top: 16.667%;
}

.recurring-event-widget .recurring-event-group:nth-child(2):before,
.recurring-event-widget .recurring-event-group:nth-child(2):after {
    top: 50%;
}

.recurring-event-widget .recurring-event-group:nth-child(3):before,
.recurring-event-widget .recurring-event-group:nth-child(3):after {
    top: 83.335%;
}

.recurring-event-widget .recurring-event-group:nth-child(4):before,
.recurring-event-widget .recurring-event-group:nth-child(4):after {
    /* top: 86%; */
}

.recurring-event-widget .recurring-event-group:after {
    border-right-color: rgb(244, 244, 244);
    border-width: 12px;
    margin-top: -12px;
}

.recurring-event-widget .recurring-event-group:before {
    border-right-color: rgb(221, 221, 221);
    border-width: 14px;
    margin-top: -14px;
}

.recurring-event-widget input[type=radio],
.recurring-event-widget input[type=checkbox] {
    margin-top: 2px;
    margin-left: 7px;
}

.recurring-event-widget .recurring-event-weekdays {
    position: absolute;
    display: inline-block;
    margin-left: -20px;
}

.recurring-event-widget .recurring-event-weekdays label {
    width: 120px;
}

/**
*
* ROLES
*/
.roles-widget {
    width: auto;
}

.roles-widget .controller {
    width: 300px;

    vertical-align: bottom !important;
}

.roles-widget .mask {
    width: 80px;

    text-align: center;
}

@media only screen and (min-width: 1025px) {
    .roles-widget .mask {
        width: 150px;
    }
}

.roles-widget th.mask {
    text-align: center !important;
}

.roles-widget .mask .checkbox {
    width: 18px;
    margin-left: auto;
    margin-right: auto;
}

.translation-item-container {
    position: relative;
    min-height: 3rem;
}

.translation-item-container .translation-item {
    display: none;

    padding-bottom: 5px;
}

.translation-item-container .translation-item .form-group div[class*="col-"],
.translation-item-container .translation-item .form-group div[class*=" col-"] {
    padding-left: 0px;
    padding-right: 0px;
}

.translation-item-container .translation-item:last-child {
    padding-bottom: 0px;
}

.translation-item-container .translation-item.active {
    display: block;
}

.translation-item-container-inner {
    position: absolute;

    width: 100%;
}

.translation-item-container.active .translation-item-container-inner {
    background-color: #fff;
    border: 1px solid #f9f9f9;

    padding: 5px;
    margin: -6px -6px;

    z-index: 10;

    -webkit-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);

    width: calc(100% + 22px);
}

.translation-item-container.active .translation-item {
    position: relative;

    display: block !important;
    width: calc(100% - 10px);
}

.translation-item-container .translation-item .translation-item-description {
    position: absolute;

    top: 8px;
    left: calc(100% - 22px);
}

.translation-item-container .translation-item .translation-item-description .language-name {
    display: none;
}

.translation-item-container .translation-item.active .translation-item-description {
    display: block;
}

.translation-item-container.active .translation-item .translation-item-description {
    display: block;
    position: absolute;
    width: 125px;

    top: 8px;
    left: calc(100% - 22px);
}

.translation-item-container.active .translation-item .translation-item-description .language-name {
    display: inline-block;

    padding-left: 5px;
}

.translation-item textarea.form-control {
    resize: none;
}

.picker--time .picker__holder {
    max-width: 100px;
}
