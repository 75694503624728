.form-control-static {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
}

.form-horizontal .form-group {
    margin-bottom: 5px;
}

.form-horizontal .form-group:last-child {
    margin-bottom: 0;
}

.form-horizontal .panel-body {
    padding: 10px;
}

.form-horizontal .tabbable.tab-content-bordered {
    margin-bottom: 10px;
}

.form-horizontal .nav-tabs-left .tabbable.tab-content-bordered {
    margin-bottom: 0;
}

.form-horizontal legend + .form-group .tabbable.tab-content-bordered {
    margin-top: -12px !important;
}

.form-horizontal .tab-content > .has-padding {
    padding: 10px;
}

.form-horizontal .control-label {
    padding-bottom: 3px;
}

.form-horizontal .form-control {
    padding: 7px 8px;
}

.form-horizontal .panel {
    margin-bottom: 10px;
}

.form-horizontal .nav-tabs-left .panel {
    margin-bottom: 25px;
}

.form-horizontal .help-block {
    margin-top: 4px;
    margin-bottom: 5px;
}

.form-horizontal legend {
    margin-bottom: 10px;
}

.form-horizontal .tabbable .collection-items .btn-add {
    top: -50px;
    right: -0px;
}

.form-horizontal .has-feedback-left .form-control {
    padding-left: 36px;
}

.form-horizontal .has-feedback-right .form-control {
    padding-right: 36px;
}

.nav-tabs-left .tab-pane .nav-tabs > li.active > a,
.nav-tabs-left .tab-pane .nav-tabs > li.active > a:hover,
.nav-tabs-left .tab-pane .nav-tabs > li.active > a:focus {
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs-left .tab-pane .nav-tabs > li > a {
    margin-right: 2px;
    border: 1px solid transparent;
}

@media (min-width: 769px) {
    .nav-tabs-vertical .nav-tabs {
        width: 250px;
    }

    .nav-tabs-left .tab-pane .nav-tabs {
        border-right: none;
    }

    .nav-tabs-vertical .tab-pane .nav-tabs {
        display: block;
        border-bottom: 1px solid #ddd;
        width: auto;
    }

    .nav-tabs-vertical .tab-pane .nav-tabs > li {
        display: inline-block;
        margin-bottom: -1px;
        margin-right: 0px;
    }

    .nav-tabs-left .tab-pane .nav-tabs-highlight > li.active > a,
    .nav-tabs-left .tab-pane .nav-tabs-highlight > li.active > a:hover,
    .nav-tabs-left .tab-pane .nav-tabs-highlight > li.active > a:focus {
        border-top-color: #2196F3;
        border-left-color: #ddd;
    }

    .nav-tabs-left .tab-pane .nav-tabs > li.active > a,
    .nav-tabs-left .tab-pane .nav-tabs > li.active > a:hover,
    .nav-tabs-left .tab-pane .nav-tabs > li.active > a:focus {
        border-bottom-color: transparent;
        border-right-color: #ddd;
    }

    .nav-tabs-left .tab-pane .nav-tabs-highlight > li > a,
    .nav-tabs-left .tab-pane .nav-tabs-highlight > li > a:hover,
    .nav-tabs-left .tab-pane .nav-tabs-highlight > li > a:focus {
        border-top-width: 2px !important;
    }

    .nav-tabs-left .tab-pane .tab-content {
        display: block;
        border-left-width: 1px !important;
        border-top-width: 0px !important;
    }
}

.nav-tabs-vertical .tab-content {
    padding-left: 0px;
}

.nav-tabs-vertical .tab-pane .panel {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.nav-tabs-vertical .tab-pane .panel-body {
    padding: 0 0;
}