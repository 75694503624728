/* https://www.materialpalette.com/colors */
/* 400 colors */

$white: #fff;
$black: #000;
$grey: #333;

$red: #ef5350;
$pink: #ec407a;
$purple: #ab47bc;
$deep-purple: #7e57c2;
$indigo: #5c6bc0;
$blue: #42a5f5;
$light-blue: #29b6f6;
$cyan: #26c6da;
$teal: #26a69a;
$green: #66bb6a;
$light-green: #9ccc65;
$lime: #d4e157;
$yellow: #ffee58;
$amber: #ffca28;
$orange: #ffa726;
$deep-orange: #ff7043;
$brown: #8d6e63;
$grey: #bdbdbd;
$blue-grey: #78909c;

:root {
  --error: #f44336 !important;
  --success: #4caf50 !important;
}
