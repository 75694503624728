body.full-height-content .content-wrapper {
    position: relative;
}

body.full-height-content .content-wrapper .content {
    height: calc(100% - 154px);
    display: block;
    position: absolute;
    width: 100%;
}

body.full-height-content .content-wrapper .content > .footer {
    bottom: -20px;
}

#crud .dt-buttons .dt-button {
    margin-left: 15px;
}

#crud .dt-buttons .dt-button:first-child {
    margin-left: 0;
}

.dataTables_paginate {
    position: absolute;
    text-align: center;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    float: none;
}

.row-actions {
    white-space: nowrap;
}


div.datatable .reorder {
    display: block;
    font-size: 24px;
    cursor: ns-resize;
    text-align: center;
}

div.datatable .row-reorder {
    display: none;
}

div.datatable.datatable-reorder .row-reorder {
    display: table-cell;
}


.default-variation {
    font-size: 18px;
    color: rgba(0, 0, 0, .54);
    text-align: center;
}

.form-horizontal .tabbable.tab-content-bordered {
    margin-bottom: 20px;
}

.form-horizontal .button-container > button {
    margin-left: .5rem;
}

.form-horizontal .button-container > button:first-child {
    margin: 0;
}

.form-horizontal .button-container .button {
    margin-left: 10px;
}

.form-horizontal .button-container .btn-default {
    background-color: #fff;
}

.form-horizontal .button-container .btn-default:hover,
.form-horizontal .button-container .btn-default:active,
.form-horizontal .button-container .btn-default:focus {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}

.dataTables_wrapper .datatable-footer .dataTables_actions {
    float: left;

    width: 275px;

    margin-right: 10px;
    padding-left: 5px;
}

.dataTables_wrapper td button {
    margin-top: -7px;
    margin-bottom: -7px;
}

.dataTables_wrapper .datatable-footer .dataTables_actions .select2-container {
    width: calc(100% - 30px) !important;
    margin-left: 10px;
}

.select2-results__options .select2-results__option span.position-left {
    width: 16px !important;
    overflow: hidden;
}

.dataTables_wrapper .datatable-footer .dataTables_length .select2-container {
    width: auto !important;
}

.datatable table.got_groups tbody tr.group_key_1 td {
    font-size: 15px !important;
    font-weight: bold !important;
    font-style: italic;
}
.datatable table.got_groups tbody tr.group_key_2 td {
    font-size: 15px !important;
    padding-left: 50px !important;
    font-style: italic;
}
.datatable table.got_groups tbody tr.group_key_3 td {
    font-size: 15px !important;
    padding-left: 70px !important;
    font-style: italic;
}
.datatable table.got_groups tbody tr.group_key_4 td {
    font-size: 15px !important;
    padding-left: 90px !important;
    font-style: italic;
}

.datatable table.got_groups.total_groups_2 thead tr th {
    padding-left: 70px;
}
.datatable table.got_groups.total_groups_3 thead tr th {
    padding-left: 90px;
}
.datatable table.got_groups.total_groups_4 thead tr th {
    padding-left: 110px;
}

.datatable table.got_groups.total_groups_2 tbody tr.normal_row td {
    font-size: 12px;
    padding-left: 70px;
}
.datatable table.got_groups.total_groups_3 tbody tr.normal_row td {
    font-size: 12px;
    padding-left: 90px;
}
.datatable table.got_groups.total_groups_4 tbody tr.normal_row td {
    font-size: 12px;
    padding-left: 110px;
}

.has-error .select2 .select2-selection {
    border-bottom-color: rgb(216, 67, 21) !important;
}


