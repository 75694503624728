[data-panel-id="tab_panel"] {
  ul.nav {
    display: flex;

    > li {
      flex: 1 1 0;

      > a {
        padding: 12px 6px;
      }
    }
  }
}

.panel__heading--sticky {
  top: 0 !important;
}
