.datatable .datatable-scroll-lg,
.datatable .datatable-scroll,
.datatable .datatable-scroll-sm {
    overflow-x: scroll;
    overflow-y: hidden;
}

.datatable .dataTables_processing {
    position: relative;
    top: 0;
    left: 0;

    text-align: center;
    font-weight: normal;
    padding: 0 24px;
    height: 48px;
    line-height: 48px;
    margin: 0;
    border-top: 1px rgba(0, 0, 0, .12) solid;
}

.datatable .table > thead > tr > th,
.datatable .table > tbody > tr > th,
.datatable .table > tfoot > tr > th,
.datatable .table > thead > tr > td,
.datatable .table > tbody > tr > td,
.datatable .table > tfoot > tr > td {
    font-weight: normal;
    padding: 0 24px;

    height: 48px;
    line-height: 1;
    vertical-align: middle;
}

.datatable .table > thead > tr > th {
    border: none;
}

.datatable .table > thead > tr > th.sorting_desc,
.datatable .table > thead > tr > th.sorting_asc {
    font-weight: 500;
    color: rgba(0, 0, 0, .87);
}

.datatable .table > thead > tr > th,
.datatable .table > tbody > tr > th,
.datatable .table > tfoot > tr > th {
    color: rgba(0, 0, 0, .54);

    font-size: 12px;
    background: #f9f9f9;
}

.datatable .table > thead > tr > td,
.datatable .table > tbody > tr > td,
.datatable .table > tfoot > tr > td {
    color: rgba(0, 0, 0, .87);

    font-size: 13px;

    border-top: 1px rgba(0, 0, 0, .12) solid;
}

.datatable .dataTables_wrapper {

}

.datatable .dataTables_wrapper[data-selection-active] {

}

.datatable .datatable-header {
    position: relative;

    letter-spacing: .005em;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 64px;

    max-height: 64px;

    padding: 0 24px;

    margin: 0;

    border-bottom: 1px rgba(0, 0, 0, .12) solid;
}

.datatable .datatable-header a i {
    font-size: 24px;

    color: rgba(0, 0, 0, .54);
}

.datatable .datatable-header a:not(.btn):hover {
    color: rgba(0, 0, 0, .87)
}

.datatable .datatable-header .actions {
    float: right;
    margin-left: 1rem;
}

.datatable .datatable-header .actions .btn {
    margin: 0 0 0 .25rem;
}

.datatable .datatable-header a.btn-disc {
    border: 1px #757575 solid;
    color: #757575;
    border-radius: 100px;
    padding: 0;
    width: 38px;
    height: 38px;
    position: relative;
}

.datatable .datatable-header a.btn-disc:hover,
.datatable .datatable-header a.btn-disc.active {
    color: #fff;
    border: 1px #26a69a solid;
    background: #26a69a;
}

.datatable .datatable-header a.btn-disc i {
    color: inherit;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}


.datatable .datatable-header .title {
    font-size: 20px;
}

.datatable .datatable-header .datatable-search {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.datatable .datatable-header .datatable-search i {
    color: rgba(0, 0, 0, .54);
    height: 24px;
    width: 24px;
    font-size: 24px;
    line-height: 1;
}

.datatable .datatable-header .datatable-search input {
    width: 100%;
    border: none;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex: 1;

    margin: 0 16px;
    font-size: 20px;
}

.datatable .datatable-header .datatable-search input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.26);
    font-weight: 300;
}

.datatable .datatable-header .datatable-buttons {
    margin: 0;
    float: none;
}

.datatable .datatable-header-processing,
.datatable .datatable-header-bulk-actions {
    position: absolute;

    width: 100%;
    height: 100%;

    left: 0;

    z-index: 1000;

    display: none;
    flex-direction: row;
    align-items: center;

    padding: 0 24px;
    background: #F5F5F5;
}

.datatable .datatable-header-bulk-actions {
    padding-right: 18px;
}

.datatable .datatable-header-processing {
    z-index: 1001;

    background: #FFFFFF;
}

.datatable .datatable-header-processing .pace_activity {
    height: 24px;
    width: 24px;
    border: 3px solid rgba(0, 0, 0, 0.54);
    border-left-color: #ffffff;
}

.datatable .datatable-header-processing.active,
.datatable .datatable-header-bulk-actions.active {
    display: flex;
}

.datatable .datatable-header-bulk-actions .count-selected-items {
    font-size: 16px;
    letter-spacing: .005em;

    display: flex;
    flex: 1;
}

.datatable-header-bulk-actions > a,
.datatable-header-bulk-actions .btn-group,
.datatable-header-bulk-actions .btn-group > a {
    display: inline-block;

    width: 30px;
    height: 24px;
    line-height: 24px;
}

.datatable-header-bulk-actions .btn-group ul li a {
    font-size: 12px;
}

.datatable-header-bulk-actions .btn-group ul li a i {
    font-size: 16px;
}

.datatable-header-bulk-actions .btn-group > a > i {
    line-height: 1;
}

.datatable tbody tr:hover td {
    background-color: #EEEEEE;
}

.datatable .dataTable tbody > tr.selected,
.datatable .dataTable tbody > tr > .selected {
    background-color: #F5F5F5;
}

.datatable .dataTable .checker span.checked {
    background: #7CB342;
    border: 1px solid #7CB342;
    color: #fff;
}

.datatable .dataTable .checker span.checked:after {
    top: 1px;
    left: 1px;

    font-size: 14px;
}

.datatable .row-actions a {
    color: rgba(0, 0, 0, .54);

    font-size: 18px;
}

.datatable .datatable-footer {
    border-top: 1px rgba(0, 0, 0, .12) solid;
    letter-spacing: .005em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;

    width: 100%;
    height: 57px;

    max-height: 57px;

    padding: 0 24px;

    font-size: 12px;
    color: rgba(0, 0, 0, .54);
}

.datatable .datatable-footer .dataTables_length {
    display: flex;
    float: none;
    margin: 0 20px 0 0;
}

.datatable .datatable-footer .dataTables_info {
    display: flex;
    float: none;
    margin: 0 4px 0 0;
}

.datatable .datatable-footer .dataTables_paginate.paging_simple {
    margin: 0;

    position: relative;
    width: auto;
    left: 0;
    text-align: left;
    float: none;
    display: flex;
}

.datatable .datatable-footer .dataTables_paginate.paging_simple a {
    padding: 0 16px;

    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;

    min-width: 0;
    font-size: 24px;
}

.datatable .datatable-filters label,
.datatable .datatable-filters label + div {
    display: block;
    float: none;
    width: auto;
}

.datatable .datatable-filters label {
    color: #263238;
    font-size: 11px;
    line-height: 1.82;
    margin: 1rem 0 0 0;
    text-transform: uppercase;
}

.datatable .datatable-filters form > div:first-child > label {
    margin: 0;
}


