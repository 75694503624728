#assortment_products_container {
    margin: 1rem 0;
    float: left;
    width: 100%;
}

#assortment_products_container .product-trash {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    border: 4px dashed #CCC;
    top: 0;

    text-align: center;

    z-index: 100;
}

#assortment_products_container .product-trash .ghost {
    opacity: 0.5;

    width: 100%;
    height: 100%;

    left: 0;
    right: 0;

    background-color: #CCC;
    text-indent: -99999px;
}

#assortment_products_container .product-trash .ghost .product-item-form-elements {
    display: none !important;
}

#assortment_products_container .product-trash .background {
    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    width: 100%;
    text-align: center;

    font-size: 16px;

    padding: 0 20px;
}

#assortment_products_container .product-trash .background .fa {
    font-size: 32px;
}

#assortment_products_container .product-trash > .drop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

#assortment_products_container .product-selector {
    position: relative;
}

#assortment_products_container .product-selector h2 {
    margin: 0;
    padding: 10px;

    background: #f0f0f0;
    border: 1px solid #ddd;
    border-bottom: none;

    font-size: 14px;
}

#assortment_products_container .product-selector .product-selector-input {
    border: 1px solid #ddd;

    padding: 5px 10px;
}

#assortment_products_container .product-selector #product_selector_search {
    margin: 5px 0;

    border-color: #eee;
}

#assortment_products_container .product-selector .product-selector-container {
    border: 1px solid #ddd;

    margin: 10px 0 0 0;

    height: 500px;
}

.product-selector-container .product-selector-result-all-used {
    padding: 10px;
    margin: 0;

    opacity: 0.6;
    font-style: italic;
}

.product-selector-container .product-selector-result-none,
.product-selector-container .search-placeholder {
    padding: 10px;
    margin: 0;

    opacity: 0.6;
    text-align: center;
}

.product-selector-container .product-selector-result-container {
    height: 468px;
    overflow-y: auto;
    overflow-x: hidden;
}

.product-selector-container .product-selector-result-heading {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: bold;
    margin-left: -1px;
    margin-right: -1px;

    padding: 6px;
}

.product-selector-result-container .product-selector-result-category {
    background: #f0f0f0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    font-size: 12px;

    padding: 5px
}

.product-selector-result-products {
    margin-left: 0;
    margin-right: 0;
}

.product-selector-container .product-selector-result-product {
    text-align: center;

    margin-bottom: 10px;

    width: 50%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;

    cursor: move;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    opacity: 1;
}

.product-selector-container .col-product-item-present {
    display: none;
}

.product-selector-container .ghost {
    opacity: 0.2;
}

.product-selector-result-product .product-item .product-item-image {

}

.product-selector-result-product .product-item .product-item-image img {
    max-width: 80%;
}

.product-selector-result-product .product-item .product-item-image .product-item-image-placeholder {
    padding-top: 10px;

    width: 92px;
    height: 92px;

    margin: 0 auto;

    opacity: 0.4;
    text-transform: uppercase;
}

.product-selector-result-product .product-item .product-item-title {
    margin-top: 5px;
}

.product-selector-result-product .product-item .product-item-price {
    display: none;
}

#assortment_products_container #assortment_products_sortable {
    height: 558px;
    margin-right: 1px;
    overflow-y: auto;
    overflow-x: hidden;
}

#assortment_products_container h2 {
    margin: 0 0 10px -10px;
    padding: 10px;

    background: #f0f0f0;
    border: 1px solid #ddd;

    font-size: 14px;
}

#assortment_products_sortable .col-product-item {
    padding-left: 0px;
    padding-right: 10px;
    cursor: move;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: 16.66666667%;

    float: left;

    position: relative;
    min-height: 1px;
}

#assortment_products_sortable .product-item {
    text-align: center;

    border: 1px solid #e8e8e9;
    padding-bottom: 10px;
    opacity: 1;

    margin-bottom: 10px;
}

#assortment_products_sortable .ghost {
    opacity: 0.2;
}

#assortment_products_sortable .product-item .product-item-image {
    height: 150px;
    max-height: 150px;
}

#assortment_products_sortable .product-item .product-item-image img {
    max-height: 100%;
}

#assortment_products_sortable .product-item .product-item-image .product-item-image-placeholder {
    padding-top: 25px;

    width: 150px;
    height: 150px;

    margin: 0 auto;

    opacity: 0.4;
    text-transform: uppercase;
}

#assortment_products_sortable .product-item .product-item-description {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;

    margin: 5px 12px 0 12px;
}

#assortment_products_sortable .product-item .product-item-price {
    display: block;
    margin-top: 2px;
}

#assortment_products_sortable .product-item .product-item-form-elements {
    display: none;
}

.company-product-label {
    position: absolute;
    right: 20px;
    top: 4px;
    opacity: 0.6;
    font-size: 11px;
}
