/*
SF debug bar fix
*/
.sf-toolbar svg {
    display: inline !important;
}

body {
    color: #212121;
}

.breadcrumb>li>a,
.sidebar-default .navigation li.active>a,
.sidebar-default .navigation li.active>a:hover,
.sidebar-default .navigation li.active>a:focus {
    color: #212121;
}

.navbar-brand>img {
    height: auto;
    max-height: 100%;
}

.page-header-default {
    margin-bottom: 10px;
}

.sidebar-user-material-content {
    max-height: 24px;
}

.sidebar-xs .sidebar-user-material-content {
    display: block;
    visibility: hidden;
}

.sidebar-user-material .category-content {
    padding: 16px;
}

.sidebar-xs .sidebar-user-material-menu,
.sidebar-user-material-menu {
    margin: -16px;
    margin-top: 16px;

    height: 40px;
}

.sidebar-xs .sidebar-user-material-menu>a,
.sidebar-user-material-menu>a {
    padding: 10px 20px;
}

.sidebar-xs .sidebar-user-material-menu>a>i {
    margin-top: 2px;
}

@media (min-width: 769px) {
    .navigation {
        padding: 0;
    }

    .sidebar-fixed .sidebar-content {
        top: 0;
    }

    .sidebar-xs .sidebar-fixed .sidebar-content {
        overflow: visible;
    }

    .sidebar-xs .sidebar-main .navigation>li>a>i {
        margin: 0;
    }

    .page-title {
        padding: 13px 0;
    }
}

.sidebar-content .alert-warning {
    margin-top: 5px;
    padding: 5px 0;
    text-align: center;
}

.sidebar-content .alert-warning a {
    color: inherit;
    border-bottom: 1px dotted rgb(170, 53, 16);
}

.navigation>li>a {
    font-weight: normal
}

.navigation>li>a>i {
    margin-top: 0;
}

.navigation>li.active>a,
.navigation>li.active>a:focus,
.navigation>li.active>a:hover {
    background-color: #26a69a;
    color: #fff;
}

.content {
    padding: 0 10px 60px 10px;
}

i+.content {
    padding: 0 !important;
}

notification {
    display: none;
}

.flagicon {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative;
    top: -2px;
    line-height: 11px;
}

.flagicon img {
    height: 11px;
    vertical-align: top;
}

/**
Panel
**/
.panel {
    border-radius: 0;
}

/**
* Form
**/
.form-horizontal .row .form-group:last-child {
    margin-bottom: 0px;
}

.modal-filemanager .modal-content {
    background-color: #f5f5f5;
}

.modal-log .modal-content {
    background-color: #f5f5f5;
}

.ui-pnotify-container a {
    float: right;
    margin-top: 10px;
    color: inherit !important;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    border-radius: 4px;
    font-style: italic;
}

.edit-assortment-product {
    position: absolute;
    top: 10px;
    right: 20px;
    display: block;
}

.internal-remark {
    border-top: 1px solid #ddd;
    margin-bottom: 5px;
    padding: 5px;
}

.icons-list {
    text-align: right;
}

.icons-list li {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
}

.icons-list li i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.company-site-color {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid #4d484b;
    display: block;
}

.company-site-table tr td {
    padding: 5px !important;
}

.company-site-logo {
    max-width: 150px;
    max-height: 150px;
    float: right;
}

.swal2-container {
    z-index: 9000;
}

.daterangepicker.dropdown-menu {
    z-index: 8000;
}

.collection-items {
    position: relative;
}

.quantityButton {
    margin-top: 10px;
    width: 100%;
}

.datatable .datatable-header-bulk-actions.active {
    z-index: 3;
}

.container-md-6 {
    width: calc(50% - 20px);
    margin: 10px;
    display: inline-block;
    vertical-align: top;
}

.container-md-12 {
    width: calc(100% - 20px);
    margin: 10px;
    display: inline-block;
    vertical-align: top;
}

form[name='product_variation_form'],
form[name='product']>.panel>.panel-body>.row>.col-md-12 {
    display: flex;
    flex-wrap: wrap;
}

form[name='product']>.panel {
    background: none;
    border: none;
    box-shadow: none;
}

select.multiselect {
    height: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    display: block !important;
    /* Required for jQuery Form Validator to validate select 'hidden' field. */
}

.trace .icon {
    display: none;
}

.bakery-tables td {
    height: 85px !important;
}

.swal2-overflow {
    overflow-x: visible;
    overflow-y: visible;
}

.form-group .row {
    display: initial;
}
