.panel-body.orders {
    overflow-y: scroll;
}

.panel-body.orders table {
    width: 100% !important;
}

.sidebar-filters label {
    color: #263238;
    font-size: 11px;
    line-height: 1.82;
    margin: 0;
    text-transform: uppercase;
}

.sidebar-filters div.field-status .form-control,
.sidebar-filters div.field-limit .form-control {
    border-bottom-color: transparent;
    box-shadow: none;
}

.sidebar-filters .sidebar-content {
    margin: 0;
}

.sidebar-filters .sidebar-content:not(:last-child) .category-content {
    padding-bottom: 0;
}

.fixedHeader-floating {
    z-index: 100;

    margin-left: -12px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.table-xs > thead > tr > th {
    box-sizing: content-box;
}

.orders .table-xs > thead > tr > th,
.orders .table-xs > tbody > tr > td,
.fixedHeader-floating thead > tr > th {
    vertical-align: top !important;
    padding: 5px 11px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
}

.fixedHeader-floating thead > tr > th {
    text-align: left;
    font-weight: bold;
}

.orders .table-xs > tbody > tr > td a {
    color: rgb(51, 51, 51);
}

.orders .internal-remark {
    width: 100%;
    height: 61px;
    resize: none;
    font-size: 11px;
    border-color: #CCCCCC;
    display: inline-block;
    vertical-align: top;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
}

.orders .btn {
    background-color: #fff;
}

.orders .btn-xs {
    margin-top: 5px;
    /*padding: 2px 7px;*/
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu { /* used to be tt-dropdown-menu in older versions */
    width: 158px;
    margin-top: -2px;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tt-suggestion {
    padding: 3px 20px;
    line-height: 24px;
}

.tt-suggestion.tt-cursor, .tt-suggestion:hover {
    color: #fff;
    background-color: #0097cf;

}

.tt-suggestion p {
    margin: 0;
}

.vue-order__list .v-menu__content {
    /* hack to prevent the menu get pushed out of the window in the legacy admin */
    left: auto !important;
    right: 2px !important;
}
