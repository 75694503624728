.content-window {
    background-color: rgba(201, 201, 201, 0.5);
    width: 100%;
    position: absolute;
    top: 0;
    display: none;
    /* padding-left: 200px; */
    overflow: hidden;
    height: 100%; /* calc(100% - 92px); */
    /* top: 94px; */
    z-index: 2000;
}

.content-window.without-background {
    background: none;
    pointer-events: none;
}
.content-window.without-background .content-window-body,
.content-window.without-background .content-window-toolbar {
    pointer-events: initial;
}

.content-window .content-window-loading {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2rem 1.5rem;
    box-sizing: border-box;
    z-index: 999999999999;
}

.content-window .content-window-loading i {
    color: #666666;
}

.content-window .content-window-loading.content-window-loading-with-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
}

.content-window .content-window-loading i {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.content-window .content-window-body {
    padding: 10px;
    background-color: #f1f1f1;
    box-shadow: -5px 0 5px -3px rgba(127, 127, 127, 0.5);
    height: calc(100% - 54px);
    overflow: auto;
    right: 0;
    margin-right: 0;
    position: fixed;
}

.content-window .content-window-body.no-scrolling {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.content-window.has-toolbar .content-window-body {
    padding-bottom: 53px;
}

.content-window .content-window-toolbar {
    bottom: 0;
    right: 0;
    position: fixed;
    padding: 10px;
    background-color: #fcfcfc;
    border-top: 1px solid #ddd;
    color: #333;
}

.content-window-toolbar .pull-left .btn {
    margin-right: 5px;
}

.content-window-toolbar .pull-left .btn-group .btn {
    margin-right: 0;
}

.content-window-toolbar .pull-left .btn-group {
    margin-right: 5px;
}

.content-window-toolbar .pull-right .save-notice {
    float: left;
    height: 34px;
    line-height: 34px;

    font-size: 12px;
    font-weight: 500;

    margin-right: 18px;
}

.content-window-toolbar .pull-right .btn {
    margin-left: 5px;
}
