.sidebar-main {
  .two-factor-auth {
    margin: 0;
    text-align: left;

    a {
      color: inherit;
    }
  }
}

.sidebar-xs {
  .sidebar-user-material-menu {
    margin: 0 !important;
    height: auto !important;
  }

  .sidebar__heading {
    padding: 0;
  }

  .two-factor-auth {
    font-size: 1.5rem;
    padding: 4px 11px;
    margin: 0 0 1rem;
    text-align: center;
    display: inline-block;
    border-radius: 2rem;

    span {
      display: none;
    }
  }
}

body:not(.sidebar-xs) .sidebar:not(.sidebar-xs) {
  .two-factor-auth {
    margin-top: 1rem;
    padding: 7px 13px;

    i {
      display: inline-block;
      float: left;
      margin: 8px 1.5rem 0 .5rem;
      font-size: 2.5rem;
    }
  }
}
