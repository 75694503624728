@import '~vuetify/dist/vuetify.min.css';

$default-font-size: 13px;

:root {
  --primary: #26a69a;
  --border-color: #dddddd;
  --danger: #ef5350;
}

.danger--text {
  color: var(--danger) !important;
}

body {
  font-size: $default-font-size;
}

// Grid conflicts with bootstrap
.row {
  margin: initial;
}

[class^="col-"] {
  padding: 0 10px;
}

.float-left {
  float: left;
}

.mr-2 {
  margin-right: .5rem;
}

.v-chip.v-size--small .v-icon {
  font-size: 15px;
}

.v-application--inline .v-btn:not(.v-btn--round).v-size--default {
  height: 34px;
}

/*
 Remove Vuetify styling on v-app tag.
*/
.v-application {
  display: block !important;
  background: none !important;

  .container:first-child {
    padding: 0 !important;
    max-width: 100% !important;
  }

  .container--fluid {
    width: auto;
  }
}

.v-application--wrap {
  display: block !important;
  flex: none !important;
  max-width: none !important;
  min-height: auto !important;
}

.v-data-table td,
.v-alert {
  font-size: $default-font-size;
}

.v-card__title.panel__heading {
  font-size: 15px;
}

.v-btn.v-size--default {
  letter-spacing: inherit;
  font-size: 12px;
  font-weight: 500;
}

/** Bugfix for correct display of popover */
.panel--vue .panel-body {
  position: initial !important;
}

.v-input fieldset {
  margin-bottom: 0;
}

.vue-order__list .v-menu__content {
  max-width: 500px !important;
}